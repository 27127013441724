<template>
  <div class="page page--team-create">
    <div class="back-button" @click="$router.push({ name: 'teamsList' })">
      <i class="material-icons">keyboard_arrow_left</i>
      <span>{{ $t("back") }}</span>
    </div>
    <div class="mem-font--page-view__title list-title">
      {{ $t("new_team") }}
    </div>

    <v-form ref="form" class="create-form" lazy-validation @submit.prevent="">
      <div class="card team-create-form">
        <div class="card__body">
          <span class="card__subtitle">
            {{ $t("choose_privacy") }}
          </span>
          <span class="card__description">
            {{ $t("choose_privacy_description") }}
          </span>

          <div class="team-types">
            <div
              v-for="(type, index) in types"
              :class="[
                `team-type`,
                form.type === type.slug ? 'team-type--active' : '',
              ]"
              :key="index"
              @click="form.type = type.slug"
            >
              <v-icon class="type__icon">{{ type.icon }}</v-icon>
              <span class="type__name">{{ $t(type.name) }}</span>
            </div>
          </div>

          <base-input
            v-model.trim="form.name"
            :label="$t('team_name')"
            class="input-field"
            :rules="rules.name"
            :error="!!errors.name"
            :error-messages="errors.name"
            counter="70"
            validate-on-blur
            @focus="onInputFocus('name')"
          />
          <base-address-search
            v-model="form.city"
            :label="$t('team_city')"
            class="input-field"
            geocode-type="city"
            :rules="rules.city"
            :error="!!errors.city"
            :error-messages="errors.city"
            validate-on-blur
            @on-place-change="setTeamCoords"
            @focus="onInputFocus('city')"
          />
          <base-textarea
            class="input-field"
            v-model.trim="form.description"
            :label="$t('description')"
            :rules="rules.description"
            :error="!!errors.description"
            :error-messages="errors.description"
            validate-on-blur
            counter="200"
            auto-grow
            :rows="1"
            @focus="onInputFocus('description')"
          >
          </base-textarea>
        </div>
      </div>
      <div class="card social">
        <div class="card__title">
          {{ $t("social") }}
        </div>
        <div class="card__body">
          <v-text-field
            v-model.trim="form.links.instagram"
            type="url"
            :label="$t('instagram_link')"
            :rules="rules.instagram"
            :error="!!errors.instagram"
            :error-messages="errors.instagram"
            validate-on-blur
            @focus="onInputFocus('instagram')"
          >
            <template v-slot:prepend-inner>
              <img
                height="18"
                width="18"
                :src="require('@/assets/instagram-gradient-icon.png')"
              />
            </template>
            <template v-slot:append v-if="!!form.links.instagram">
              <v-icon class="clear-field" @click="clearField('instagram')">
                remove_circle
              </v-icon>
            </template>
          </v-text-field>
          <v-text-field
            v-model.trim="form.links.facebook"
            :label="$t('facebook_link')"
            :rules="rules.facebook"
            :error="!!errors.facebook"
            :error-messages="errors.facebook"
            validate-on-blur
            @focus="onInputFocus('facebook')"
          >
            <template v-slot:prepend-inner>
              <img :src="require('@/assets/facebook-icon.svg')" />
            </template>
            <template v-slot:append v-if="!!form.links.facebook">
              <v-icon class="clear-field" @click="clearField('facebook')">
                remove_circle
              </v-icon>
            </template>
          </v-text-field>
          <v-text-field
            v-model.trim="form.links.strava"
            type="url"
            :label="$t('strava_link')"
            :rules="rules.strava"
            :error="!!errors.strava"
            :error-messages="errors.strava"
            validate-on-blur
            @focus="onInputFocus('strava')"
          >
            <template v-slot:prepend-inner>
              <img
                height="18"
                width="18"
                :src="require('@/assets/strava-icon.svg')"
              />
            </template>
            <template v-slot:append v-if="!!form.links.strava">
              <v-icon class="clear-field" @click="clearField('strava')">
                remove_circle
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="buttons">
        <v-btn
          class="button-submit"
          :class="{ disabled: buttonDisabled }"
          :loading="loading"
          color="#FFFFFF"
          depressed
          rounded
          large
          dark
          @click="onFormSubmit"
        >
          <span class="action__desktop">{{ $t("save") }}</span>
          <span class="action__mobile">{{ $t("create_team") }}</span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import BaseTextarea from "@/components/base/BaseTextarea.vue";
import {
  censorValidator,
  domainValidator,
  requireValidator,
} from "@/common/validators";
import BaseInput from "@/components/base/BaseInput";
import BaseAddressSearch from "@/components/base/BaseAddressSearch";
import { mapActions } from "vuex";

export default {
  name: "CreateTeamView",
  components: { BaseAddressSearch, BaseInput, BaseTextarea },
  data() {
    return {
      loading: false,
      types: [
        {
          id: 0,
          name: "team_type_public",
          slug: "public",
          icon: "public",
          isActive: true,
        },
        {
          id: 1,
          name: "team_type_privacy",
          slug: "privacy",
          icon: "lock",
          isActive: false,
        },
      ],
      form: {
        type: "public",
        name: "",
        city: "",
        description: "",
        links: {
          instagram: "",
          facebook: "",
          strava: "",
        },
      },
      teamCoords: {},
      errors: {},
      rules: {
        name: [
          (val) => requireValidator(val) || this.$t("field_required"),
          (val) => censorValidator(val) || this.$t("error_team_abuse_word"),
        ],
        city: [
          (val) => censorValidator(val) || this.$t("error_team_abuse_word"),
        ],
        description: [
          (val) => censorValidator(val) || this.$t("error_team_abuse_word"),
        ],
        instagram: [
          (val) =>
            domainValidator(val, "instagram.com") ||
            this.$t("instagram_link_error"),
        ],
        facebook: [
          (val) =>
            domainValidator(val, "facebook.com") ||
            this.$t("facebook_link_error"),
        ],
        strava: [
          (val) =>
            domainValidator(val, "strava.com") || this.$t("strava_link_error"),
        ],
      },
    };
  },
  computed: {
    buttonDisabled() {
      return !this.form.name;
    },
  },
  methods: {
    ...mapActions(["createTeam"]),
    async onFormSubmit() {
      this.errors = {};
      this.loading = true;
      const form = this.$refs.form;
      if (form?.validate()) {
        const payload = this.prepareForm();
        try {
          const result = await this.createTeam(payload);
          if (result?.name) {
            if (result?.slug) {
              await this.$router.push(`/teams/${result?.slug}`);
            } else {
              await this.$router.push("/teams/");
            }
          }
        } catch (e) {
          if (e.status === 422 && e.data?.errors) {
            const errors = Object.entries(e.data.errors);
            errors.forEach(([key, value]) => {
              const [msg] = value;
              this.$set(this.errors, key, this.$t(`error_team_${msg}`));
            });
          } else {
            console.error(e);
          }
        }
      }
      this.loading = false;
    },
    setTeamCoords(place) {
      let { geometry } = place || {};

      this.teamCoords.locationGeoLat = geometry?.lat || null;
      this.teamCoords.locationGeoLon = geometry?.lng || null;
    },
    prepareForm() {
      return {
        name: this.form.name,
        description: this.form.description,
        isPublic: this.form.type === "public",
        location: this.form.city,
        instagramLink: this.form.links.instagram,
        facebookLink: this.form.links.facebook,
        stravaLink: this.form.links.strava,
        locationGeoLat: this.teamCoords.locationGeoLat || null,
        locationGeoLon: this.teamCoords.locationGeoLon || null,
      };
    },
    clearField(name) {
      if (this.form.links[name]) {
        this.form.links[name] = "";
      }
    },
    onInputFocus(field) {
      console.log("focus", field);
      this.$delete(this.errors, field);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/sass/variables";
.page--team-create {
  padding-bottom: 180px;
  .back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    margin-bottom: 24px;

    position: relative;
    font-weight: bold;
    font-size: 14px;
    @include cursorPointer;

    // margin-bottom: 40px;
    span {
      line-height: 14px;
      margin-left: 20px;
    }

    text-transform: uppercase;

    i {
      position: absolute;
      left: -8px;
    }
  }

  .list-title {
    margin: 30px 0 36px 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    max-width: min(670px, 100%);
    width: 100%;
    background: #121212;
    border-radius: 5px;
    padding: 36px 32px 24px;
    align-items: center;
    margin: 0;

    & + .card {
      margin-top: 8px;
    }

    &__body {
      max-width: 413px;
      width: 100%;
      padding: 28px 0 40px 0;
    }

    ::v-deep(.v-label) {
      text-transform: none;
    }
  }

  .team-create-form {
    .card__subtitle {
      display: block;
      font-size: 14px;
      font-weight: 600;
      color: $text-grey;
      margin-bottom: 13px;
    }

    .card__description {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      color: $text-grey;
      margin-bottom: 24px;
    }

    .team-types {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 7px;
      margin-bottom: 52px;

      .team-type {
        display: flex;
        align-items: center;
        border: 1px solid #717171;
        border-radius: 8px;
        padding: 15px;
        cursor: pointer;

        .type__icon {
          margin-right: 10px;
          color: $text-grey;
        }

        .type__name {
          font-size: 14px;
          font-weight: 700;
        }

        &--active {
          border: 1px solid #ffffff;

          .type__icon {
            color: #ffffff;
          }

          .type__name {
            color: #ffffff;
          }
        }
      }
    }

    .input-field {
      ::v-deep .v-label {
        top: -5px;

        &.v-label--active {
          top: 0;
        }
      }
      &.input-error {
        .v-label {
          color: #cf1019;
          text-align: center;
          height: 20px;
          font-weight: bold;
        }
      }
      &.base-textarea {
        ::v-deep .v-label {
          top: 2px;

          &.v-label--active {
            top: 7px;
          }
        }
      }

      & + .input-field {
        margin-top: 10px;
      }
    }
  }

  .social {
    .card__title {
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      padding-left: 2px;
    }

    .card__body {
      padding-bottom: 20px;
      padding-top: 42px;
    }

    .v-input {
      ::v-deep .v-input__prepend-inner {
        margin-top: -2px;
        padding-right: 10px;
      }
      ::v-deep .v-input__append-inner {
        margin-top: -2px;
        padding-right: 10px;

        .clear-field {
          color: #606060;

          &:hover {
            color: white;
            cursor: pointer;
          }
        }
      }
      ::v-deep .v-label {
        top: -5px;

        &.v-label--active {
          top: 0;
        }
      }
    }
  }

  .buttons {
    max-width: min(670px, 100%);
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .button-submit {
      display: flex;
      flex: 1 0;
      max-width: 202px;
      color: #020000;
      font-weight: 700;
      margin-top: 27px;
      &.disabled {
        background-color: rgba(255, 255, 255, 0.2) !important;
      }
      .action__mobile {
        display: none;
      }
      ::v-deep(.v-btn__content) {
        font-weight: 700;
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .page--team-create {
    padding-bottom: 90px;
    .back-button {
      font-size: 16px;
      margin-left: 16px;
      margin-top: 30px;
      margin-bottom: 0;
    }

    .list-title {
      margin-left: 16px;
      margin-top: 16px;
    }

    .card {
      padding: 0 24px 24px;

      &__body {
        padding-bottom: 0;
        padding-top: 37px;
      }
    }

    .team-create-form {
      .team-types {
        gap: 16px;
        margin-bottom: 24px;

        .team-type {
          padding: 13px;
        }
      }

      .card__description {
        max-width: 80%;
        margin-bottom: 28px;
      }

      .input-field {
        & + .input-field {
          margin-top: 10px;
        }
      }
    }

    .social {
      padding-top: 30px;

      .card__title {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        padding-left: 0;
      }

      .card {
        padding-top: 14px;
        padding-bottom: 0;

        &__body {
          padding-top: 21px;
          padding-bottom: 0;
        }
      }
    }

    .buttons {
      padding-right: 16px;
      .button-submit {
        margin-top: 12px;
        .action__desktop {
          display: none;
        }

        .action__mobile {
          display: inline-block;
        }
      }
    }
  }
}
</style>
